var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.integrationsData
    ? _c(
        "div",
        [
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            style: { width: "100%", height: "200px" },
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.integrationsData,
              gridOptions: _vm.gridOptions,
              rowSelection: "single",
              pagination: false,
              paginationPageSize: _vm.paginationPageSize,
              suppressPaginationPanel: true,
              context: _vm.context,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }