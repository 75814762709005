var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "supplier-health-stats w-full" }, [
    _c(
      "div",
      {
        staticClass: "supplier-health-stats-main",
        class: { "multi-line-stat": _vm.multiLine },
        style: _vm.borderStyle,
      },
      [_c("div", { staticClass: "text-sm" }, [_vm._v(_vm._s(_vm.statValue))])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }