var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.params.data.isEditable
    ? _c(
        "div",
        [
          _vm.userHasPermission("apps_integrations_edit")
            ? _c("feather-icon", {
                attrs: {
                  title: "Edit",
                  icon: "Edit3Icon",
                  svgClasses: "h-5 w-5 mr-4 hover:text-primary cursor-pointer",
                },
                on: { click: _vm.onEdit },
              })
            : _vm._e(),
          _vm.userHasPermission("apps_integrations_edit")
            ? _c("feather-icon", {
                attrs: {
                  title: "Delete",
                  icon: "TrashIcon",
                  svgClasses: "h-5 w-5 hover:text-danger cursor-pointer",
                },
                on: {
                  click: function ($event) {
                    _vm.showDeletePopup = true
                  },
                },
              })
            : _vm._e(),
          _c(
            "vs-prompt",
            {
              attrs: {
                title: "Delete Integration",
                "accept-text": "Delete",
                active: _vm.showDeletePopup,
              },
              on: {
                accept: _vm.deleteIntegrations,
                "update:active": function ($event) {
                  _vm.showDeletePopup = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row mt-2 mb-2" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("div", {}, [
                    _vm._v(
                      "Are you sure you want to delete the current integration? Upon deletion, corresponding monthly charge will be removed from your subscription"
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }