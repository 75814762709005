var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasLoaded
    ? _c(
        "travio-center-container",
        {
          attrs: {
            "grid-width": "full",
            pageTitle: "Suppliers Directory - " + _vm.form.supplierName,
          },
        },
        [
          _c(
            "vx-card",
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap items-center mb-4" },
                  [
                    _c("vs-avatar", {
                      staticClass: "supplier-logo-avatar mr-2",
                      attrs: {
                        src: _vm.form.logoUrl,
                        text: _vm.form.supplierName,
                        size: "60px",
                      },
                    }),
                    _c("div", { staticClass: "ml-2" }, [
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap items-center" },
                        [
                          _c(
                            "h2",
                            { staticClass: "supplier-name-heading mr-2" },
                            [_vm._v(_vm._s(_vm.form.supplierName))]
                          ),
                          _vm.form.isPremium
                            ? _c("vs-chip", { attrs: { color: "warning" } }, [
                                _vm._v("PREMIUM"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "text-muted mt-1" }, [
                        _vm._v(
                          _vm._s(_vm.form.productType) +
                            "  •  " +
                            _vm._s(_vm.form.apiType)
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-row mb-3" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                    _c("span", { staticClass: "font-semibold" }, [
                      _vm._v("Description"),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                    _c("p", [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.form.supplierDescription),
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "vx-row mb-3" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                    _c("span", { staticClass: "font-semibold" }, [
                      _vm._v("Setup Price*"),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                    _c("p", [
                      _vm._v("£ " + _vm._s(_vm.form.setupPrice.toFixed(2))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "vx-row mb-2" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                    _c("span", { staticClass: "font-semibold" }, [
                      _vm._v("Monthly Price*"),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                    _c("p", [
                      _vm._v("£ " + _vm._s(_vm.form.monthlyPrice.toFixed(2))),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "mb-3" }, [
                  _c("span", { staticClass: "text-muted text-sm" }, [
                    _vm._v(
                      "*prices shown above are travelgenix charges & there may be additional supplier charges involved."
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "vx-row mb-3" }, [
                  _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                    _c("span", { staticClass: "font-semibold" }, [
                      _vm._v("Setup Information"),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                    _vm.form.requiresSetup
                      ? _c("span", [
                          _vm._v(
                            "This supplier requires setup by travelgenix.io"
                          ),
                        ])
                      : _vm._e(),
                    !_vm.form.requiresSetup
                      ? _c("span", [_vm._v("Self-service setup")])
                      : _vm._e(),
                  ]),
                ]),
                _vm.form.chargesInfo
                  ? _c("div", { staticClass: "vx-row mb-3" }, [
                      _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v("Supplier Charge Information"),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                        _c("p", [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.form.chargesInfo),
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.form.accountContact
                  ? _c("div", { staticClass: "vx-row mb-3" }, [
                      _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v("Account Setup Information"),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                        _c("p", [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.form.accountContact),
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.form.postBookContact
                  ? _c("div", { staticClass: "vx-row mb-8" }, [
                      _c("div", { staticClass: "vx-col sm:w-1/5 w-full" }, [
                        _c("span", { staticClass: "font-semibold" }, [
                          _vm._v("Post-Booking Support"),
                        ]),
                      ]),
                      _c("div", { staticClass: "vx-col sm:w-4/5 w-full" }, [
                        _c("p", [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.form.postBookContact),
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm.checklistItems.length > 0
                ? _c("div", { staticClass: "mt-8" }, [
                    _c(
                      "span",
                      { staticClass: "font-medium text-lg leading-none" },
                      [_vm._v("Important Checklist Items")]
                    ),
                  ])
                : _vm._e(),
              _vm.checklistItems.length > 0
                ? _c("supplier-integrations-checklist", {
                    attrs: { checklistItems: _vm.checklistItems },
                  })
                : _vm._e(),
              _vm.form.productTypeSearchable
                ? _c(
                    "div",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "h5",
                        {
                          staticClass: "font-medium text-lg leading-none mb-4",
                        },
                        [
                          _vm._v("Supplier Health "),
                          _vm.form.statusUrl
                            ? _c("span", { staticClass: "text-sm" }, [
                                _vm._v("  "),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.form.statusUrl,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v("Open supplier status page")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      !_vm.healthCheckItems || _vm.healthCheckItems.length == 0
                        ? _c("div", [_vm._v("Loading supplier health....")])
                        : _vm._e(),
                      _vm.healthCheckItems && _vm.healthCheckItems.length > 0
                        ? _c("supplier-health-check-list", {
                            attrs: { healthCheckItems: _vm.healthCheckItems },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "mt-6" }, [
                _c(
                  "span",
                  { staticClass: "font-medium text-lg leading-none" },
                  [_vm._v("My " + _vm._s(_vm.form.supplierName) + " Accounts")]
                ),
                !_vm.form.productTypeSearchable && _vm.form.statusUrl
                  ? _c("span", { staticClass: "text-sm" }, [
                      _vm._v("  "),
                      _c(
                        "a",
                        {
                          attrs: { href: _vm.form.statusUrl, target: "_blank" },
                        },
                        [_vm._v("Open supplier status page")]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _vm.userHasPermission("apps_integrations_add")
                  ? _c(
                      "div",
                      { staticClass: "vx-col w-full mt-4" },
                      [
                        _c(
                          "vs-button",
                          {
                            staticClass: "mr-4 sm:mb-0 mb-2",
                            on: {
                              click: function ($event) {
                                _vm.showAddIntegrationPopup = true
                                _vm.selectedChecklistItems = []
                              },
                            },
                          },
                          [_vm._v("Add")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                [
                  _c("supplier-integrations-list", {
                    attrs: {
                      integrations: _vm.integrationsData,
                      applicationId: _vm.applicationId,
                    },
                    on: { onDeleteIntegration: _vm.handleDeleteIntegration },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vs-prompt",
            {
              attrs: {
                title: "Add Supplier Integration",
                "accept-text": "Continue",
                "is-valid": _vm.enableConfirmButton,
                active: _vm.showAddIntegrationPopup,
              },
              on: {
                cancel: _vm.closeAddIntegrationDialog,
                close: _vm.closeAddIntegrationDialog,
                accept: _vm.addIntegration,
                "update:active": function ($event) {
                  _vm.showAddIntegrationPopup = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row mt-2 mb-2" }, [
                _vm.form.requiresSetup
                  ? _c("div", { staticClass: "vx-col w-full" }, [
                      _c("span", [_vm._v(_vm._s(_vm.requiresSetupVerbiage))]),
                    ])
                  : _vm._e(),
                !_vm.form.requiresSetup
                  ? _c("div", { staticClass: "vx-col w-full mb-2" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.doesNotRequireSetupVerbiage)),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm.checklistItems.length > 0
                ? _c(
                    "div",
                    { staticClass: "vx-row mb-2" },
                    [
                      _c("div", { staticClass: "vx-col w-full" }, [
                        _vm._v("\n        Checklist Items:\n      "),
                      ]),
                      _vm._l(
                        _vm.checklistItems,
                        function (checklist, checklistIndex) {
                          return _c(
                            "div",
                            {
                              key: checklistIndex,
                              staticClass: "vx-col w-full mt-2",
                            },
                            [
                              _c(
                                "vs-checkbox",
                                {
                                  attrs: {
                                    title: checklist.description,
                                    "vs-value": checklist.id,
                                  },
                                  model: {
                                    value: _vm.selectedChecklistItems,
                                    callback: function ($$v) {
                                      _vm.selectedChecklistItems = $$v
                                    },
                                    expression: "selectedChecklistItems",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(checklist.title) +
                                      "\n        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }