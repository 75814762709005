var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.healthCheckItems && _vm.healthCheckItems.length > 0
    ? _c(
        "div",
        { staticClass: "vx-row" },
        _vm._l(_vm.healthCheckData, function (stat, index) {
          return _c(
            "div",
            { key: index, staticClass: "vx-col lg:w-1/4 xs:w-full" },
            [
              _c("div", { staticClass: "text-sm" }, [
                _vm._v("Mean " + _vm._s(stat.bookStage) + " Time"),
              ]),
              stat.requestCount7Days > 0
                ? _c("travelify-supplier-health-check-stats", {
                    attrs: {
                      statValue: stat.meanTimeLastHour,
                      color: stat.meanTimeLastHourColor,
                    },
                  })
                : _vm._e(),
              stat.requestCount7Days > 0
                ? _c("travelify-supplier-health-check-stats", {
                    attrs: {
                      color: stat.meanTimeLast7DaysColor,
                      statValue: stat.meanTimeLast7Days,
                    },
                  })
                : _c("travelify-supplier-health-check-stats", {
                    attrs: { statValue: "No data", color: "#e2e2e2" },
                  }),
              _c("div", { staticClass: "text-sm mt-4" }, [
                _vm._v(_vm._s(stat.bookStage) + " Error Rates"),
              ]),
              stat.requestCount7Days > 0
                ? _c("travelify-supplier-health-check-stats", {
                    attrs: {
                      color: stat.errorRateLastHourColor,
                      statValue: stat.errorRateLastHour,
                    },
                  })
                : _vm._e(),
              stat.requestCount7Days > 0
                ? _c("travelify-supplier-health-check-stats", {
                    attrs: {
                      color: stat.errorRateLast7DaysColor,
                      statValue: stat.errorRateLast7Days,
                    },
                  })
                : _c("travelify-supplier-health-check-stats", {
                    attrs: { statValue: "No data", color: "#e2e2e2" },
                  }),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }